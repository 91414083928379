.loaderMGC {
  animation: spin infinite 1.5s;
}

.loaderMGC.linear {
  animation: spin infinite 1.5s linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
