@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=09529873-f1ee-4caa-a99d-bccada8c6d05);
body {
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{font-family:'Helvetica Neue Light';src:url(/static/media/1b950d2a-907a-4888-8056-0d44e8e86338.9325c672.eot);src:url(/static/media/1b950d2a-907a-4888-8056-0d44e8e86338.9325c672.eot) format("eot"),url(/static/media/3a46542f-f429-4821-9a88-62e3be54a640.9b361977.woff2) format("woff2"),url(/static/media/50ac1699-f3d2-47b6-878f-67a368a17c41.afc80fd3.woff) format("woff"),url(/static/media/0be5590f-8353-4ef3-ada1-43ac380859f8.9bb46038.ttf) format("truetype")}@font-face{font-family:'Helvetica Neue Regular';src:url(/static/media/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.4a2b52a7.eot);src:url(/static/media/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.4a2b52a7.eot) format("eot"),url(/static/media/3dac71eb-afa7-4c80-97f0-599202772905.4a334318.woff2) format("woff2"),url(/static/media/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.cca18f47.woff) format("woff"),url(/static/media/8a8bfee3-197c-4942-9b11-71508cc9f406.e18f9243.ttf) format("truetype")}@font-face{font-family:'Helvetica Neue Bold';src:url(/static/media/9697cfef-7816-47cc-81ed-c56c029d3bcf.233c32cf.eot);src:url(/static/media/9697cfef-7816-47cc-81ed-c56c029d3bcf.233c32cf.eot) format("eot"),url(/static/media/1f4274cd-2674-481e-9023-69e33ffca161.a9c7349a.woff2) format("woff2"),url(/static/media/5e8d493c-be01-44b9-962e-504a5cf10dd8.bab93ac2.woff) format("woff"),url(/static/media/ae36b4a0-aa2c-46cc-98d8-d8b7d22241dc.cb4b37f3.ttf) format("truetype")}@font-face{font-family:'Helvetica Neue Medium';src:url(/static/media/5640036d-d549-4f16-b31e-87da78d9e832.6a0762f4.eot);src:url(/static/media/5640036d-d549-4f16-b31e-87da78d9e832.6a0762f4.eot) format("eot"),url(/static/media/240c57a0-fdce-440d-9ce3-85e0cb56f470.44c2f9f5.woff2) format("woff2"),url(/static/media/7802e576-2ffa-4f22-a409-534355fbea79.a58fa207.woff) format("woff"),url(/static/media/14d824a2-2a5c-4692-8e30-74f6e177b675.9be7fba4.ttf) format("truetype")}article{display:flex;margin:0 auto;max-width:1280px;width:100%}html,body{scroll-behavior:smooth}.hidden{display:none !important}

.loaderMGC{-webkit-animation:spin infinite 1.5s;animation:spin infinite 1.5s}.loaderMGC.linear{-webkit-animation:spin infinite 1.5s linear;animation:spin infinite 1.5s linear}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}

.arrow{margin:0;text-align:center}.arrow.bounce{-webkit-animation:bounce 2s infinite;animation:bounce 2s infinite}.arrow>span{cursor:pointer;opacity:0.5;transition:opacity 0.3s;-webkit-transition:opacity 0.3s}.arrow>span:hover{opacity:1}@-webkit-keyframes bounce{0%,20%,50%,80%,100%{-webkit-transform:translateY(0);transform:translateY(0)}40%{-webkit-transform:translateY(-10px);transform:translateY(-10px)}60%{-webkit-transform:translateY(-5px);transform:translateY(-5px)}}@keyframes bounce{0%,20%,50%,80%,100%{-webkit-transform:translateY(0);transform:translateY(0)}40%{-webkit-transform:translateY(-10px);transform:translateY(-10px)}60%{-webkit-transform:translateY(-5px);transform:translateY(-5px)}}

