@import './article.scss';

html,
body {
  scroll-behavior: smooth;
}

.hidden {
  display: none !important;
}
