.arrow {
  margin: 0;
  text-align: center;

  &.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }

  > span {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
